import React from 'react';

const faqs = [
  {
    sectionHeader: '',
    questions: [
      {
        question: 'How do I get a website?',
        answer: (
          <span>
            Just click “Getting Started” on{' '}
            <a href="https://gofisherman.com/">https://gofisherman.com/</a>. Answer the questions in
            the form and our software will auto-generate a complete website for you. We’ll send you
            a preview link to your complete website where you can connect a custom domain and take
            the website live in one click.
          </span>
        ),
      },
      {
        question: 'How do I publish the website?',
        answer: (
          <span>
            When you receive the preview link to your complete website, you’ll see a “Publish
            Website” option either in the bottom right corner of the website or on a banner at the
            top of the website. Once you click “Publish”, you’ll have the option to select a new
            domain for free or connect an existing one. After selecting your packaging and
            completing checkout, we’ll take care of connecting your domain and you will be notified
            when your website is published.
          </span>
        ),
      },
      {
        question: 'How do I make edits to the website?',
        answer: (
          <span>
            Before publishing the website, you can click “Request an Edit” on the preview link
            banner at the top of the page. If you want to make an edit after your website is live,
            simply request access to a content editor by emailing info@gofisherman.com.
          </span>
        ),
      },
      {
        question: 'How much do Fisherman websites cost?',
        answer: (
          <span>
            The Basic package costs $20 per month and includes a secure, mobile-optimized website
            that is completely built and updated for you, basic Search Engine Optimization, and a
            free custom domain. Our Premium package additionally includes advanced SEO to drive
            traffic to your website and phone support. For specific design and feature requests, we
            have an Advanced tier with custom quoting.
          </span>
        ),
      },
      {
        question: 'Is the website secure?',
        answer: (
          <span>
            Yes, all of our websites include https and SSL security to protect your website.
          </span>
        ),
      },
      {
        question: 'I have an existing website but I want a new one.',
        answer: (
          <span>
            Great! When Fisherman technology generates a new website for your business, it
            automatically pulls information from your existing website to make sure nothing
            important gets lost.
          </span>
        ),
      },
      {
        question: 'Is the website mobile-friendly?',
        answer: (
          <span>
            Yes, Fisherman websites are automatically designed to be mobile-optimized so that
            visitors can experience your online presence from any device.
          </span>
        ),
      },
      {
        question: 'Who hosts the website?',
        answer: (
          <span>
            Fisherman hosts all websites using Amazon Web Services. We take care of all of the
            hosting setup automatically when you sign up for a website.
          </span>
        ),
      },
      {
        question: 'Can I transfer my current domain?',
        answer: (
          <span>
            Yes, contact Fisherman support to help you transfer your domain to your new website.
          </span>
        ),
      },
      {
        question:
          'Can I connect my POS Menu, Online Ordering, and other restaurant technologies to my website?\n',
        answer: (
          <span>
            Yes, Fisherman works with many FoodTech companies, including several POS systems, to
            sync your website menu, online ordering, and other features directly with your website.
            Email info@gofisherman.com with any requests and we’ll make sure the appropriate
            technologies are set up.
          </span>
        ),
      },
    ],
  },
];

export default faqs;
