import React from 'react';

import { List, Header, Image, Container } from 'semantic-ui-react';

import FishermanLogo from '../../data/logos/FishermanRedTextLogo.svg';

import './FAQ.css';

export default class FAQ extends React.PureComponent {
  render() {
    const { title, faqs } = this.props;
    return (
      <Container className="faq-container">
        <Image src={FishermanLogo} centered size="medium" alt="Fisherman Logo" />
        <Header as="h1" textAlign="center">
          {title}
        </Header>
        <div className="faq-content">
          {faqs.map(({ sectionHeader, questions }) => (
            <div key={sectionHeader} className="faq-content-section">
              <Header as="h2">{sectionHeader}</Header>
              <List size="large" relaxed>
                {questions.map(({ question, answer }) => (
                  <List.Item key={question}>
                    <List.Content>
                      <List.Header>{question}</List.Header>
                      <List.Description>{answer}</List.Description>
                    </List.Content>
                  </List.Item>
                ))}
              </List>
            </div>
          ))}
        </div>
      </Container>
    );
  }
}
